import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Decoration = makeShortcode("Decoration");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const ParagraphSlider = makeShortcode("ParagraphSlider");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const BlockProjects = makeShortcode("BlockProjects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnimateIntroBanner id="banner" className="background_logo dark" background={<><RowLines mdxType="RowLines" /><Decoration from="/sector-industrial-accommodation/banner.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroBanner">  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Industrial Accommodation
    </Heading>
    <LearnMore elementId="projects_list" color="white" mdxType="LearnMore" />
  </Block>
  <Block className="content_block" mdxType="Block">
    <ParagraphSlider mdxType="ParagraphSlider">
    <Paragraph mdxType="Paragraph">Developments often come with a need to secure the accommodation, health, and safety of those behind the scenes. Hausman can manage accommodations and facilities for developments of any type and size, at various stages within a project.
    </Paragraph>
    <Paragraph mdxType="Paragraph"> This includes Temporary Camps, Construction Worker Camps, Permanent Dormitories, Refugee Camps, Military Camps, Oil & Gas Camps, and Prisons.
    </Paragraph>
    </ParagraphSlider>
  </Block>  
    </AnimateIntroBanner>
    <Row id="projects_list" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <BlockProjects sector="industrial-accommodation" flipped={true} mdxType="BlockProjects" />
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      